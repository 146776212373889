import React, { useContext, useMemo, useState, useEffect } from 'react';
import "./news-detail.scss";
import { Link, useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import { AppContext } from '../../services/translationContext';
import { Loader } from '../../components/loader/loader';
import axios from 'axios';
import XMLParser from 'react-xml-parser';

const api = process.env.REACT_APP_URL_API;

export const NewsDetail = () => {
  // let { news_id } = useParams();
  // const { services: {TranslationsService} } = useContext(AppContext);
  // const [newsInfo, setNewsInfo] = useState([]);
  // const [showLoader, setShowLoader] = useState(false);

  // useMemo(() => {
  //   setShowLoader(true);
  //   fetch(`${api}/content-public?url_path=${news_id}`)
  //   .then(response => response.json())
  //   .then(data => {
  //     setNewsInfo(data.data[0]);
  //     document.title = `${TranslationsService.labels(`client_stories`)} - ${data.data[0].title} | ${TranslationsService.getMainInfoCompany('name')}`;
  //     setShowLoader(false);
  //   });
  // }, [TranslationsService, news_id]);

  // const getHtmlContent = () => {
  //   try{
  //     let htmlText = Buffer.from(newsInfo.content, "base64");
  //     return htmlText;
  //   }
  //   catch(e){
  //     return '';
  //   }

  // }

  // return (
  //   <div className="section-home light">
  //     {
  //       showLoader &&
  //       <Loader />
  //     }
  //     <section className='hero-story-det syd-hero position-relative p-0' style={{backgroundImage:newsInfo.image?`url(https://d3t3s6w5yvhc3g.cloudfront.net/images/${newsInfo.image})`:''}}>
  //       <div className='overlay-area-info w-100 d-flex flex-column justify-content-center p-5'>
  //         <p className='dark-mode-text m-0 breadcrumb-detail'>
  //           <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
  //           &nbsp;&#9656;&nbsp;
  //           <Link to='/sydea-blog' className='text-deco-none dark-mode-text transition-03s-eio'>Blog</Link>
  //         </p>
  //         <h2 className='syd-title light text-uppercase'>{newsInfo.title}</h2>
  //         <p className='dark-mode-text fs-1 m-0'>{newsInfo.description}</p>
  //       </div>
  //     </section>

  //     <div dangerouslySetInnerHTML={{ __html: getHtmlContent() }} className='w-100 p-3 area-text-cs'></div>
  //   </div>
  // );

  let { news_id } = useParams();
  const { services: {TranslationsService} } = useContext(AppContext);
  const [newsInfo, setNewsInfo] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://www.carloliaci.com/feed', {
          headers: {'Content-Type': 'application/xml; charset=utf-8'},
        });

        const xml = new XMLParser().parseFromString(response.data);
        const items = xml.getElementsByTagName('item');

        setPosts(items);
        console.log(items);
      } catch (error) {
        setError('Errore nel fetch dei dati: ' + error.message);
      } finally {
        setShowLoader(false);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className="section-home light">
      {
        showLoader &&
        <Loader />
      }
      <section className='hero-story-det syd-hero position-relative p-0' style={{backgroundImage:newsInfo.image?`url(https://d3t3s6w5yvhc3g.cloudfront.net/images/${newsInfo.image})`:''}}>
        <div className='overlay-area-info w-100 d-flex flex-column justify-content-center p-5'>
          <p className='dark-mode-text m-0 breadcrumb-detail'>
            <Link to='/' className='text-deco-none dark-mode-text transition-03s-eio'>Home</Link>
            &nbsp;&#9656;&nbsp;
            <Link to='/sydea-blog' className='text-deco-none dark-mode-text transition-03s-eio'>Blog</Link>
          </p>
          <h2 className='syd-title light text-uppercase'>{newsInfo.title}</h2>
          <p className='dark-mode-text fs-1 m-0'>{newsInfo.description}</p>
        </div>
      </section>

      {/* <div>
        <h1>WordPress Posts</h1>
        <ul>
          {posts.map((post, index) => (
            <li key={index}>
              <h2>{post.title[0]}</h2>
              <div dangerouslySetInnerHTML={{ __html: post.description[0] }} />
            </li>
          ))}
        </ul>
      </div> */}

      {/* <div dangerouslySetInnerHTML={{ __html: getHtmlContent() }} className='w-100 p-3 area-text-cs'></div> */}
    </div>
  );
};

